<template>
	<BaseLoading v-if="isLoading" />
	<FormSubHeaders
		v-else
		:data="dataDetail"
		:is-loading="isLoading"
		:is-updating="isUpdating"
		:sub-header-type="subHeaderType"
		:router-to-cancel="routerToSuccess"
		:router-to-remove="routerToSuccess"
		is-edit-mode
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import FormSubHeaders from '@/components/FormSubHeaders.vue';

import { mapActions, mapGetters, mapState } from 'vuex';
import { scrollToTop } from '../assets/js/helpers';
import { ROUTE_NAME } from '../enums/route';
import { SUB_HEADER_TYPES, SUB_HEADER_COMPONENTS } from '../enums/subHeader';

export default {
	name: 'SubHeaderEdit',

	components: {
		FormSubHeaders,
	},

	props: {
		subHeaderType: {
			type: String,
			default: SUB_HEADER_TYPES.HOMEPAGE,
		},
		routerToSuccess: {
			type: String,
			default: ROUTE_NAME.SUB_HEADER_HOMEPAGE,
		},
	},

	data() {
		return {
			ROUTE_NAME,
			id: Number(this.$route.params.id) || null,
		};
	},

	computed: {
		...mapState('subHeaders', {
			edit: 'edit',
		}),

		...mapGetters({
			getSubHeaderDetail: 'subHeaders/getSubHeaderDetail',
		}),

		isLoading() {
			return this.edit.isLoading;
		},

		isUpdating() {
			return this.edit.isUpdating;
		},
		dataDetail() {
			return this.getSubHeaderDetail;
		},
	},

	async created() {
		await this.getSubHeader({ id: this.id });
		const title = SUB_HEADER_COMPONENTS[this.subHeaderType].title;
		const routeTo = ROUTE_NAME[`SUB_HEADER_${this.subHeaderType}`];
		this.addBreadcrumb({
			title,
			route: {
				name: routeTo,
			},
		});
	},

	methods: {
		...mapActions({
			updateSubHeader: 'subHeaders/updateSubHeader',
			getSubHeader: 'subHeaders/getSubHeader',
			addBreadcrumb: 'breadcrumb/addBreadcrumb',
		}),

		async handleSubmit(params = {}) {
			try {
				const body = {
					...params,
					type: this.subHeaderType,
				};
				await this.updateSubHeader({
					id: this.id,
					params: body,
				});
				this.$router.push({ name: this.routerToSuccess });
			} catch {
				scrollToTop();
			}
		},
	},
};
</script>
